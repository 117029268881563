<template>
  <v-container class="fill-height">
    <v-row>
      <v-col class="text-center px-10">
        <v-img
          contain
          width="600"
          class="mx-auto"
          src="https://ik.imagekit.io/5zmdzllq3/undraw_page_not_found_su7k_9DKVsz4k5P8.svg"
        ></v-img>
        <h1 class="text-capitalize title tw-text-primary">
          halaman tidak ditemukan
        </h1>
        <v-btn color="primary" depressed @click="$router.go(-1)">Kembali</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
